// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

#navbar-static-logo {
  @include media-breakpoint-up(lg) {
    visibility: hidden;
  }
  img {
    max-height: 4rem;
  }
}
.navbar-sticky-on #navbar-static-logo {
  visibility: visible;
}

// navbar expand sm
@include media-breakpoint-down(sm) {
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    > .nav-item {
      padding: 5px 10px;
    }
  }
}

#sponsor-cell-text, #sponsor-cell-logo {
  height: 60px;
}
#sponsor-cell-logo {
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}
@include media-breakpoint-down(md) {
  #sponsor-container {
    width: 100%;
  }
}
@include media-breakpoint-up(md) {
  #sponsor-cell-text,
  #sponsor-cell-logo {
    width: 180px;
  }
}
@include media-breakpoint-up(lg) {
  #sponsor-container {
    width: 180px;
  }
  #sponsor-cell-text {
    height: 45px;
  }
}
@include media-breakpoint-down(lg) {
  #sponsor-cell-text {
    padding-right: 0.5rem;
  }
  #sponsor-cell-logo {
    padding-left: 0.5rem;
  }
}

@include media-breakpoint-down(sm) {
  .shortnews-background {
    background: radial-gradient(circle, #fff, #f9f9f9 50%, #f0f0f0 100%);
  }
}
