// 
// type.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// text force color
.text-white-force *:not(.btn):not(.dropdown-item):not(.dropdown-item > *):not(.badge){
  color: $white;
}

//
// Emphasis
//
small,
.small {
  font-weight: $font-weight-light;
}

.smaller {
  font-size: 60%;
  font-weight: $font-weight-normal;
}

// dropcap
.dropcap{
  font-size: 4em;
  font-weight: bold;
  display: block;
  float: left;
  margin: .04em .2em 0 0;
  color: $headings-color;
  line-height: 1;
}

// Bg blur
.bg-blur{
  backdrop-filter: blur(5px);
}

// Social media colors

// facebook
.bg-facebook{
  background-color: #5d82d1;
  color: $white;
  &:hover{
    background-color: shift-color(#5d82d1, 10%);
    color: $white;
  }
}
.text-facebook{
  color: #5d82d1;
  &:hover{
    color: shift-color(#5d82d1, 10%);
  }
}

// instagram
.bg-instagram{
  background: #c22b72;
  color: $white;
  &:hover{
    background-color: shift-color(#c22b72, 10%);
    color: $white;
  }
}
.text-instagram{
  color: #c22b72;
  &:hover{
    color: shift-color(#c22b72, 10%);
  }
}

// instagram
.bg-instagram-gradient{
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: $white;
  background-size: 120% 120%;
  background-position: right bottom;
  transition: $transition-base;
  border: 0;
  &:hover{
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    color: $white;
    background-size: 100% 100%;
    transition: $transition-base;
    background-position: left bottom;
  }
}
.text-instagram-gradient{
  color: transparent;
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  transition: $transition-base;
  background-size: 120% 120%;
  &:hover{
    color: transparent;
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 100% 100%;
    transition: $transition-base;
  }
}

// google
.bg-google{
  background-color: #3c7ff1;
  color: $white;
  &:hover{
    background-color: shift-color(#3c7ff1, 10%);
    color: $white;
  }
}
.text-google{
  color: #3c7ff1;
  &:hover{
    color: shift-color(#3c7ff1, 10%);
  }
}
.text-google-icon {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

// twitter
.bg-twitter{
  background-color: #40bff5;
  color: $white;
  &:hover{
    background-color: shift-color(#40bff5, 10%);
    color: $white;
  }
}
.text-twitter{
  color: #40bff5;
  &:hover{
    color: shift-color(#40bff5, 10%);
  }
}

.bg-twitter-x{
  background-color: #000000;
  color: $white;
  &:hover{
    background-color: shift-color(#000000, 10%);
    color: $white;
  }
}
.text-twitter-x{
  color: #000000;
  &:hover{
    color: shift-color(#000000, 10%);
  }
}

// signal
.bg-signal{
  background-color: #3a76f0;
  color: $white;
  &:hover{
    background-color: shift-color(#3a76f0, 10%);
    color: $white;
  }
}
.text-signal{
  color: #3a76f0;
  &:hover{
    color: shift-color(#3a76f0, 10%);
  }
}

// whatsapp
.bg-whatsapp{
  background-color: #25d366;
  color: $white;
  &:hover{
    background-color: shift-color(#25d366, 10%);
    color: $white;
  }
}
.text-whatsapp{
  color: #25d366;
  &:hover{
    color: shift-color(#25d366, 10%);
  }
}

// telegram
.bg-telegram{
  background-color: #0088cc;
  color: $white;
  &:hover{
    background-color: shift-color(#0088cc, 10%);
    color: $white;
  }
}
.text-telegram{
  color: #0088cc;
  &:hover{
    color: shift-color(#0088cc, 10%);
  }
}

// linkedin
.bg-linkedin{
  background-color: #238cc8;
  color: $white;
  &:hover{
    background-color: shift-color(#238cc8, 10%);
    color: $white;
  }
}
.text-linkedin{
  color: #238cc8;
  &:hover{
    color: shift-color(#238cc8, 10%);
  }
}

// xing
.bg-xing{
  background-color: #126567;
  color: $white;
  &:hover{
    background-color: shift-color(#126567, 10%);
    color: $white;
  }
}
.text-xing{
  color: #126567;
  &:hover{
    color: shift-color(#126567, 10%);
  }
}

// vet-magazin
.bg-vetmagazin{
  background-color: #f68b1f;
  color: $white;
  &:hover{
    background-color: shift-color(#f68b1f, 10%);
    color: $white;
  }
}
.text-vetmagazin{
  color: #f68b1f;
  &:hover{
    color: shift-color(#f68b1f, 10%);
  }
}

// pinterest
.bg-pinterest{
  background-color: #e60023;
  color: $white;
  &:hover{
    background-color: shift-color(#e60023, 10%);
    color: $white;
  }
}
.text-pinterest{
  color: #e60023;
  &:hover{
    color: shift-color(#e60023, 10%);
  }
}

// dribbble
.bg-dribbble{
  background-color: #f7659c;
  color: $white;
  &:hover{
    background-color: shift-color(#f7659c, 10%);
    color: $white;
  }
}
.text-dribbble{
  color: #f7659c;
  &:hover{
    color: shift-color(#f7659c, 10%);
  }
}

// youtube
.bg-youtube{
  background-color: #ff0000;
  color: $white;
  &:hover{
    background-color: shift-color(#ff0000, 10%);
    color: $white;
  }
}

.text-youtube{
  color: #ff0000;
  &:hover{
    color: shift-color(#ff0000, 10%);
  }
}

// skype
.bg-skype{
  background-color: #13c1f3;
  color: $white;
  &:hover{
    background-color: shift-color(#13c1f3, 10%);
    color: $white;
  }
}
.text-skype{
  color: #13c1f3;
  &:hover{
    color: shift-color(#13c1f3, 10%);
  }
}

// 
// blockquote
// 

.blockquote {
  position: relative;
  padding-left: 80px;

  &:before {
    content: '"';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 8rem;
    line-height: 0.8em;
    font-weight: $font-weight-bold;
    color: $primary;
  }
}
.blockquote-footer{
  margin-top: 12px;
  padding-left: 80px;
}

// blockquote bg primary
.blockquote.blockquote-primary {
  background: $primary;
  color: $white;
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: $white;
  }
  &:before {
    color: $white !important;
    top: 20px;
    left: 20px;
  }
}

// blockquote bg dark
.blockquote.blockquote-dark {
  background: $dark;
  color: $white;
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: $white;
  }
  &:before {
    top: 20px;
    left: 20px;
  }
}

// blockquote bg light
.blockquote.blockquote-light {
  background: $gray-200;
  color: $gray-700;
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: $gray-700;
  }
  &:before {
    top: 20px;
    left: 20px;
  }
}

// blockquote primary line
.blockquote.blockquote-line {
  padding: 10px 20px;
  border-left: 5px solid $primary;
  &:before {
    display: none;
  }
}
.blockquote.blockquote-icon-bg{
  padding: 0;
  &:before {
    font-size: 18rem;
    color: $dark;
    opacity: 0.3;
    z-index: -1;
  }
  .blockquote-footer{
    padding: 0;
  }
}
