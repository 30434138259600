// add controller specific styles here
.invalid-feedback-before-checkbox {
  display: block;
  padding: 0;
  border: none;
  color: $form-feedback-invalid-color;
  background-color: transparent;
  margin-top: 1em;
  margin-bottom: 0.5em;
}
