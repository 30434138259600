// begin::Fonts
// store fonts locally (gdpr)
//@import '../stylesheets/fonts';
// end::Fonts
//
//
// theme.scss
//
// Custom variables followed by theme variables followed by Bootstrap variables
// to ensure cascade of styles.
//

// Bootstrap variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Theme variables
@import '../stylesheets/blogzine/variables';

// Theme variables dark
@import '../stylesheets/blogzine/dark/variables-dark';

// User variables
@import '../stylesheets/blogzine/user-variables';

// Bootstrap core
@import '../stylesheets/bootstrap-custom';

// Plugins CSS
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap-icons';
@import '~tiny-slider/src/tiny-slider';
//@import '~glightbox/src/postcss/glightbox';

// Extended bootstrap utilities
@import '../stylesheets/blogzine/custom/utilities';
@import '~bootstrap/scss/utilities/api';

// Extended bootstrap components
@import '../stylesheets/blogzine/custom/breadcrumb';
@import '../stylesheets/blogzine/custom/buttons';
@import '../stylesheets/blogzine/custom/card';
@import '../stylesheets/blogzine/custom/dropdowns';
@import '../stylesheets/blogzine/custom/list-group';
@import '../stylesheets/blogzine/custom/nav';
@import '../stylesheets/blogzine/custom/navbar';
@import '../stylesheets/blogzine/custom/pagination';
@import '../stylesheets/blogzine/custom/progress';
@import '../stylesheets/blogzine/custom/reboot';
@import '../stylesheets/blogzine/custom/type';
@import '../stylesheets/blogzine/custom/badge';
@import '../stylesheets/blogzine/custom/tables';

// Theme components
@import '../stylesheets/blogzine/components/apexchart';
@import '../stylesheets/blogzine/components/general';
@import '../stylesheets/blogzine/components/avatar';
@import '../stylesheets/blogzine/components/divider';
@import '../stylesheets/blogzine/components/overlay';
@import '../stylesheets/blogzine/components/tiny-slider';
@import '../stylesheets/blogzine/components/utilities';

// In this scss you can write your scss/css
@import '../stylesheets/blogzine/user';
@import '../stylesheets/blogzine/user-dark';

// import specific styles for controllers/views
@import '../stylesheets/controllers';

.body_end {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
